.keyArtworkRowCol {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  padding: 0 1%;
}

.keyArtworkBoxSectionWrapper {
  margin-right: 10px;
  margin-bottom: 10px;
}

.keyArtworkBoxSection {
  display: flex;
  flex-direction: column;
  width: 208px;
}

.keyArtworkImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.keyArtworkDetailsWrapper {
  background: white;
  padding: 8px;
  min-height: 121px;
  max-height: 121px;
}

.keyArticleSectionTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.15;
  padding: 7px 0;
  color: #323232;
}

.keyArtworkSectionPara {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.keyArtworkImg {
  position: relative;
}

.keyartworkIcon {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

/*  ################################################   */

@media (min-width: 1148px) {
  .keyArticleSectionTitle {
    font-size: 16px;
  }
}

@media (min-width: 1528px) {
  .keyArticleSectionTitle {
    font-size: 16px;
  }
}
