body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
button,
input,
textarea,
select {
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
}

.bannerImgshow {
  display: block;
  width: 100%;
}

.bannerTextHeading {
  position: relative;
  padding: 20px 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}
.bannerContentWrapper {
  position: relative;
  padding: 20px 0;
}
.bannerContentText {
  position: relative;
  color: #ffffff;
}

.bcPlayButtonSize {
  position: relative;
  z-index: 1;
  width: 70px;
}
.bcPlayButtonSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bcPlayButtonCursor {
  width: 100%;
  cursor: pointer;
}

.ourtakeMobileWrapper {
  padding-top: 10px;
  font-size: 16px;
  color: #666666;
}
.festiflixSlider .blackOverlayshow {
  background: rgba(15, 15, 15, 0.15);
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
}
.festiflixSlider .individualBox:hover > .blackOverlayshow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hpBannerPlay .blackOverlayshow {
  display: none;
}
.hpBannerPlay:hover .blackOverlayshow {
  display: block;
}
.festiflixSlider .blackOverlayshow .playSVGshow {
  height: 41px;
  width: 41px;
}
.blackOverlayshow a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playCircleshow {
  stroke: #ffffff;
  -webkit-transition: stroke 0.15s;
  transition: stroke 0.15s;
  stroke-width: 1px;
  /* vector-effect: non-scaling-stroke; */
}
.playSVGshow:hover .playPathshow {
  fill: #001489;
}
.playSVGshow:hover .playCircleshow {
  stroke: #001489;
}
.playPathshow {
  fill: none;
  -webkit-transition: fill 0.15s;
  transition: fill 0.15s;
}

.bgImageSection {
  width: 100%;
  margin-bottom: 20px;
}
.bgImagePlaceholder {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 49.819% 65.778%;
  background-size: cover;
}
.bgImageSectionWrapper {
  position: relative;
  width: 100%;
  height: 605px;
}

.bgImgSectionHeading {
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 15px;
}
.bgImgSectionHeadingImage {
  width: 300px;
  display: block;
}
.bgImageSectionContent {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
}
.bgImageSectionButton {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.bgImageSectiontext {
  color: rgb(255, 255, 255);
  display: block;
  position: relative;
  padding-bottom: 170px;
  margin: 0 20px;
}
.bgImageSectionBtn {
  appearance: none;
  background: no-repeat transparent;
  outline: none;
  margin: 0px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  color: rgb(255, 255, 255);
  height: 45px;
  padding: 0px 15px 0px 21px;
  font-size: 15px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 23px;
  transition: color 0.15s ease 0s, background-color 0.15s ease 0s;
}
.bgImageSectionBtnPlay {
  margin-left: 13px;
  width: 15px;
  height: 16px;
  position: relative;
}
.bgImageSectionBtnSVGshow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bgImageSectionBtnText {
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.1px;
}
.bgImageSlider svg path {
  stroke: #fff;
}

/* Styling the default Slick dots */

@media (min-width: 1148px) {
  .bannerImgMobileshow,
  .mobileMenu,
  .menu-overlay,
  .dotsSlider .bannerImgMobileshow {
    display: none;
  }
  .bannerTextHeading {
    font-size: 14px;
  }

  .bgImageSectionWrapper {
    height: 685px;
  }
  .bgImageSectiontext {
    margin: 0 138px;
  }
  .bgImageSectionContent {
    line-height: 18px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .bannerTextHeading {
    padding: 29px 0;
  }
  .bannerContentWrapper {
    padding: 35px 0;
  }
  /* .bcFilmDay, 
	.bcFilmDayHeading, 
	.bcFilmDaySubTitle, 
	.bcFilmDayDesc {
		font-weight: 700;
	}
	.asDescription {
		margin-bottom: 55px;
	} */

  .bgImageSectionGradient {
    padding: 35px 0px;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  .bgImageSectiontext {
    padding-bottom: 192px;
  }
  .bgImageSectionContent {
    max-width: 348px;
  }
}

@media (min-width: 1148px) and (min-height: 670px) {
  .hpBannerContainer {
    height: calc(100vh - 100px);
  }
}
@media (min-width: 1528px) {
}
@media (min-width: 320px) and (max-width: 1147px) {
  .menuItems > div:first-child,
  .hamburgerDesktop,
  .bannerTextHeading,
  .bannerImgshow,
  .autoSlider .row img.bannerImgshow,
  .prevMask,
  .menuItems .profile,
  .menuItems > a {
    display: none;
  }

  .hpBannerContainer {
    height: calc((100vh - 60px) * 0.72);
  }
  img.bannerImgMobileshow {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .hpBannerText {
    bottom: 0;
    position: absolute;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .bcFilmDay {
    font-size: 12px;
  }

  .asOverlayContent {
    margin: 0 20px;
  }
  .overlayHeader .navLogin {
    display: none;
  }
  .overlayHeader {
    height: 70px;
    position: relative;
    z-index: 10;
    justify-content: space-between;
    background: rgba(15, 15, 15, 1);
  }
  .menu-overlay {
    background: #fff;
    height: 100vh;
    width: 80%;
    left: -80%;
    z-index: 11;
    position: absolute;
    overflow: hidden;
  }
}

/*Inner page CSS*/

.showingCategory {
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
}
.showingCategoryWrapper {
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.detailsBannerWrapper {
  margin: 0 auto;
  padding: 0 15px;
}
.showingCategorySection {
  display: block;
  padding-top: 22px;
}
.showingCategorTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #666666;
  margin-bottom: 20px;
}
.showingCategoryLI {
  list-style: none;
  width: 100%;
  height: 78px;
  margin-bottom: 10px;
  display: inline-block;
}
.showingCategoryBox {
  display: flex;
  height: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(50, 50, 50);
}
.showingCategoryBoxImage {
  min-width: 94px;
  width: 94px;
  height: 100%;
}
.showingCategoryBoxImage img {
  height: 100%;
}
.showingCategoryBoxContent {
  padding: 12px 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}
.showingAwards {
  width: 100%;
  padding-top: 22px;
}
.showingAwardsTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
.showingAwardsTitle {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #666666;
}
.showingAwardsShowAll {
  color: #666666;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
}
.showingAwardsDisplay {
  /* display: flex; */
  display: -webkit-box;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}
.showingAwardsSeperateBox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  width: 100%;
}
.showingAwardsBoxWrapper {
  position: relative;
  width: 100%;
  display: table;
  min-height: 60px;
}
.showingAwardsBoxSVG {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.showingAwardsImage {
  width: 76px;
  height: 60px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.showingAwardsFeather {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.showingAwardsBoxText {
  font-weight: normal;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.showingAwardsTextLink {
  font-weight: bold;
  position: relative;
  text-decoration: none;
  display: inline-block;
  margin-top: -2px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  line-height: 20px;
  text-align: center;
}
.showingAwardsYear {
  position: relative;
  font-size: 14px;
  color: #7d7d7d;
  line-height: 20px;
  text-align: center;
}
.showingCastCrew {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding-top: 22px;
  padding-bottom: 12px;
}
.showingCastCrewUL {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: left;
  margin: 0 1%;
}
.showingCastCrewBox {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 48%;
}
.showingCastCrewBox:nth-of-type(even) {
  margin-right: 0;
}
.showingCastCrewLI {
  width: 100%;
  height: 100%;
  background: #ffffff;
  list-style: none;
  position: relative;
}
.showingCastCrewImageWrapper {
  position: relative;
}
.showingCastCrewImage {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.showingCastCrewPadding {
  display: block;
  padding: 13px;
  line-height: 1.25;
}
.showingCastCrewName {
  color: #666666;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.showingCastCrewPosition {
  color: #9b9b9b;
  font-size: 12px;
  display: block;
  margin-top: 3px;
  text-transform: uppercase;
}
.showingPreviewMargin {
  margin-bottom: 13px;
  padding-top: 85px;
  /* padding-top: 100px; */
}
.articleSectionContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 22px;
}
.articleSectionRowCol {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.articleSectionBox {
  margin: 10px 0;
  border-bottom: 1px solid #c9c2c2;
}
.articleSectionHeading {
  font-size: 18px;
  color: #666666;
  margin: 0 0 20px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  font-weight: bold;
}
.articleSectionBoxPadding {
  padding-bottom: 5px;
  position: relative;
  width: 100%;
  min-width: 100%;
  font-size: 14px;
}
.articleSectionImgText {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.articleSectionImg {
  /* width: 100%; */
  padding: 0;
  margin: 0;
  min-height: 185px;
}
.articleSectionImg a,
.articleSectionImg img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
.articleSectionText {
  padding: 0;
  margin: 0;
}
.articleSectionTextWidth {
  max-width: 600px;
}
.articleSectionTextAuthor {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  margin: 0;
}
.articleSectionTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.15;
  padding: 7px 0;
  color: #323232;
}
.articleSectionPara {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.articleSectionAuthorWrap {
  display: flex;
  width: 90%;
  align-items: flex-start;
  line-height: 1;
}
.articleSectionAuthorBold {
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: bold;
  margin-right: 2px;
  text-decoration: none;
  color: inherit;
}
.articleSectionAuthorDate {
  color: #9b9b9b;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  display: flex;
  align-items: baseline;
}
.articleSectionAuthorDate::before {
  color: #9b9b9b;
  content: "•";
  display: inline-block;
  font-size: 14.4px;
  line-height: 0;
  margin: 0 5px;
  vertical-align: middle;
  height: 0px;
}
.articleSectionCommentBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 10%;
  color: #9b9b9b;
  font-size: 12px;
  font-weight: normal;
  line-height: 0.7;
}
.articleSectionCommentIcon {
  width: 18px;
  position: relative;
  user-select: none;
}
.articleSectionCommentBox span {
  padding-top: 2px;
  height: 100%;
  margin-left: 5px;
}
.criticsReviewWrapper {
  padding: 30px 0px 20px;
}
.criticsReviewHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.criticsReviewBox {
  width: 100%;
  margin-bottom: 40px;
  padding: 10px 0px;
}
.criticsReviewInner {
  width: 100%;
  color: rgb(50, 50, 50);
}
.criticsReviewImgContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 40px;
  overflow: hidden;
}
.criticsReviewText {
  font-family: Georgia;
  font-size: 17px;
  line-height: 26px;
}
.criticsReviewAuthor {
  margin-top: 10px;
  border-top: 3px solid rgb(0, 0, 0);
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.criticsReviewName {
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  text-transform: uppercase;
}
.criticsReviewDate {
  font-size: 12px;
  color: rgb(155, 155, 155);
  line-height: 21px;
}
.criticsFullReviewLink {
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  color: rgb(50, 50, 50);
}
.criticsReviewTitle {
  align-items: flex-end;
}
.criticsReviewH2 {
  line-height: 1;
  font-size: 18px;
  color: rgb(102, 102, 102);
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.criticsStarRating {
  top: 4px;
  position: relative;
}
.criticsShowAll {
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
  color: rgb(50, 50, 50);
}
.relatedFilmsUL {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
}
.relatedFilmsLI {
  list-style: none;
  width: 100%;
}
.relatedFilmsArticle {
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin-bottom: 12px;
}
.relatedFilmsImgContainer {
  position: relative;
  color: rgb(255, 255, 255);
  background-color: rgb(55, 45, 44);
  height: 226px;
}
.relatedFilmsImgAnchor {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.relatedFilmsContent {
  height: 100%;
  width: 100%;
  padding: 19px 23px;
  position: absolute;
  inset: 0px;
}
.relatedFilmsTitle {
  text-shadow: rgb(0 0 0 / 9%) 0px 0px 10px;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
}
.relatedFilmsSubtitle {
  color: rgb(255, 255, 255);
  line-height: 20px;
  margin-top: 0px;
}
.relatedFilmsSubtitle .spanBold {
  font-weight: 700;
}
.relatedFilmsPara {
  position: relative;
  color: rgb(50, 50, 50);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0px 15px 20px;
  min-height: 226px;
}
.relatedFilmsParaMargin {
  margin-top: auto;
}
.relatedFilmsTopBottom {
  padding-right: 20px;
  margin-bottom: 18px;
  margin-top: 18px;
}
.relatedFilmsMoreInfoWrapper {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 25px;
  padding-right: 20px;
}
.relatedFilmsMoreInfo {
  white-space: nowrap;
  text-transform: uppercase;
  color: rgb(155, 155, 155);
  font-size: 12px;
  font-weight: bold;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.relatedFilmsMoreInfoIcon {
  width: 6px;
  height: 10px;
  margin-left: 3px;
  position: relative;
  user-select: none;
}
.relatedFilmsMoreIconSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.relatedFilmsImage {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.relatedFilmsImage::after {
  background: linear-gradient(
    rgba(0, 0, 0, 0.376) 0%,
    transparent 50%,
    transparent 75%,
    rgba(0, 0, 0, 0.25) 100%
  );
  content: " ";
  display: block;
  position: absolute;
  inset: 0px;
}
.relatedFilmsImage > img {
  width: 100%;
}
.relatedFilmsContent .blackOverlayshow {
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}
.theseListFlex {
  display: flex;
  justify-content: start;
  flex-direction: column;
}
.theseListFlexBox {
  width: 100%;
}
.theseListFlexBoxWrapper {
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
}
.theseListSection {
  display: flex;
  flex-wrap: wrap;
  color: rgb(102, 102, 102);
  transition: filter 175ms ease 0s, color 175ms ease 0s;
}
.theseListsTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 20px 0px;
}
.theseListsAuthor {
  display: block;
  margin-bottom: 12px;
  padding: 0px 20px;
  font-size: 12px;
  color: rgb(155, 155, 155);
}
.theseListsFilmFollower {
  padding: 0px 20px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
}
.theseListsFilmFollowerLink {
  font-size: 12px;
  margin-right: 8px;
  color: rgb(155, 155, 155);
  font-weight: bold;
}
.theseListsFilmFollowerLink:hover,
.theseListsFilmFollowerLink span {
  color: rgb(0, 20, 137);
}
.theseListsFollow {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity 175ms ease 0s;
  width: 142px;
  height: 26px;
}
.theseListFlexBox:hover .theseListsFollow {
  visibility: visible;
  opacity: 1;
}
.theseListsFollowBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 20px;
  line-height: 1.5;
  color: white;
  background-color: rgb(0, 20, 137);
  border: 1px solid rgb(0, 20, 137);
  font-size: 12px;
  width: 100%;
  height: 100%;
}
.theseListSectionRow {
  width: 66.6%;
  padding-right: 1px;
  height: 138px;
}
.theseListSectionCol {
  display: flex;
  flex-flow: column;
  width: 33.3%;
  height: 138px;
  padding-left: 1px;
}
.theseListSectionColBox {
  height: calc(50% - 1px);
  margin-bottom: 1px;
  background-color: rgb(200, 200, 200);
}
.theseListFlexBoxWrapper img {
  width: 100%;
}
.peopleSayingUL {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.peopleSayingLI {
  list-style: none;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.peopleSayingBox {
  padding: 20px 20px 25px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.peopleSayingPhotoNameRating {
  color: rgb(50, 50, 50);
  vertical-align: baseline;
  display: flex;
  position: relative;
  min-height: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  font-weight: bold;
}
.peopleSayingPhotoName {
  flex: 1 1 0%;
  display: flex;
  font-size: 14px;
  color: rgb(50, 50, 50);
  opacity: 0.8;
}
.peopleSayingPhoto {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  flex-shrink: 0;
}
.peopleSayingName {
  color: rgb(50, 50, 50);
  margin-top: -1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.peopleSayingFollow {
  visibility: hidden;
  opacity: 0;
  transition: opacity 175ms ease 0s;
  height: 26px;
  width: 90px;
  white-space: nowrap;
}
.peopleSayingBox:hover .peopleSayingFollow {
  visibility: visible;
  opacity: 1;
}
.peopleSayingFollowBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 20px;
  line-height: 1.5;
  color: white;
  background-color: rgb(0, 20, 137);
  border: 1px solid rgb(0, 20, 137);
  font-size: 12px;
  width: 100%;
  height: 100%;
}
.peopleSayingPara {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(102, 102, 102);
  overflow-wrap: break-word;
}
.peopleSayingTime {
  color: rgb(50, 50, 50);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px 20px 10px;
  font-size: 12px;
}
.peopleSayingTime > a {
  opacity: 0.6;
}
.peopleSayingRatingBox {
  position: absolute;
  top: 21px;
  left: 58px;
}
.peopleSayingFavComment {
  display: flex;
}
.peopleSayingFav {
  cursor: pointer;
  margin-right: 3px;
}
.peopleSayingFavBox {
  width: 19px;
  height: 17px;
  position: relative;
  user-select: none;
}
.peopleSayingCommentBox {
  width: 17px;
  height: 17px;
  position: relative;
  user-select: none;
}
.peopleSayingSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.peopleSayingFavNumber {
  color: rgb(102, 102, 102);
  font-size: 12px;
  margin-top: 3px;
  min-width: 4px;
}
.peopleSayingComment {
  margin-top: 1px;
  margin-left: 12px;
  margin-right: 3px;
}
.personalisation {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 12px 0 20px;
  height: 58px;
  z-index: 2;
  position: relative;
}
.personalisationSpacing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.personalisationSeperate:first-of-type {
  padding-left: 0px;
}
.personalisationSeperate {
  padding-left: 12px;
}
.personalisationSeperate:hover {
  opacity: 0.7;
}
.personalisationRatingBtn {
  appearance: none;
  color: inherit;
  background: no-repeat transparent;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.personalisationListBtn {
  display: flex;
  appearance: none;
  color: inherit;
  background: no-repeat transparent;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}
.personalisationBtnBox {
  width: 28px;
  height: 28px;
  position: relative;
  user-select: none;
}
.personalisationCursor {
  cursor: pointer;
}
.castPersonalisationOverlay {
  position: absolute;
  right: 11px;
  bottom: 11px;
  width: 29px;
  height: 29px;
  z-index: 1;
}
.relatedFilmsPersonalisation {
  z-index: 2;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 16px 18px;
  display: none;
}
.relatedFilmsImage:hover .relatedFilmsPersonalisation {
  display: block;
}
.profile {
  height: 100%;
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
}
.profileAnchor {
  display: inline-block;
}
.profileImage {
  display: block;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  transition: opacity 0.2s;
}
.profileNotification {
  height: 100%;
  width: 40px;
  position: relative;
  z-index: 4;
  display: inline-flex;
  align-items: center;
}
.profileNotificationBox {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.profileNotificationCircle {
  height: 12px;
  width: 12px;
  background-color: #c8c8c8;
  border-radius: 50%;
  cursor: pointer;
}

@media (min-width: 320px) {
  .showingCategoryWrapper,
  .detailsBannerWrapper {
    max-width: 385px;
  }
  .showingCastCrewImgBox {
    height: 198px;
  }
  .articleSectionBoxPadding {
    width: 100%;
  }
  .dpBannerTrailerBtnWrapper {
    display: none;

    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .personalisation {
    padding: 12px 0 0;
  }
  .dpBannerTrailerBtnWrapper {
    display: block;
    right: 0px;
    bottom: 5px;
    position: relative;
    float: right;
  }
  .dpBannerTrailerAnchorBtn {
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 999px;
    padding: 6px 13px;
    transition: border-color 0.15s ease 0s, background-color 0.15s ease 0s;
    display: flex;
    align-items: center;
  }
  .dpBannerTrailerAnchorBtn:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 20, 137);
    border-color: rgb(0, 20, 137);
  }
  .dpBannerTrailerPlay {
    width: 10px;
    height: 11px;
    position: relative;
    user-select: none;
    margin-left: 10px;
  }
  .bannerHeight {
    min-height: 100vh;
  }
  .hpBanner {
    height: calc(100vh - 50px);
  }
  .hpBannerContainer {
    height: 100%;
  }
  .dpBanner,
  .detailsBannerSection {
    height: calc(100vh - 50px);
  }
  .festiflixSlider .individualBox {
    height: 192px;
  }
  .festiflixSlider .individualBox .moviePoster {
    display: block;
    height: 100%;
  }
  .articleSectionContainer {
    min-width: 370px;
  }
  .showingCastCrewBox:nth-of-type(even) {
    margin-right: 10px;
  }
  .showingCastCrewBox {
    width: 180px;
  }
  .watchThisNowtext {
    margin: 0 25px;
  }
  .watchThisNowBtnWrapper {
    margin-top: 14px;
  }
  .showingCategoryWrapper,
  .detailsBannerWrapper {
    max-width: 750px;
  }
  .showingCategorySection {
    padding-top: 43px;
  }
  .showingCategoryUL {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1%;
  }
  .showingCategoryLI {
    width: 370px;
    margin-right: 10px;
  }
  .showingAwards {
    padding-top: 43px;
  }
  .showingAwardsDisplay {
    padding-top: 20px;
  }
  .showingAwardsSeperateBox {
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .showingCastCrew {
    /* padding-top: 43px;
    padding-bottom: 34px; */
    padding-top: 32px;
    padding-bottom: 8px;
  }
  .showingCastCrewImgBox {
    height: 214px;
  }
}
@media (min-width: 1148px) {
  .showingCategoryWrapper,
  .detailsBannerWrapper {
    /* max-width: 1130px; */
    max-width: 1024px;
    padding: 0;
  }
  .showingAwardsSeperateBox {
    width: 25%;
  }
  .showingCastCrewUL {
    /* max-height: 325px; */
    min-width: 380px;
    max-width: none;
  }
}
@media (min-width: 1528px) {
  .showingCategoryWrapper,
  .detailsBannerWrapper {
    /* max-width: 1510px; */
    max-width: 1408px;
  }
}

/* Details page banner section */
.detailsBannerSection {
  position: relative;
  overflow: hidden;
}
.detailsBannerWrapper {
  position: relative;
}
.bsTotalRating {
  position: relative;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}
.bsTotalRatingColor {
  color: #ffffff;
}
.detailsBannerImgWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #010101;
}
.bsTotalRatingBold {
  display: flex;
  align-items: baseline;
  font-weight: bold;
}
.bsTotalRatingIcon {
  width: 20px;
  height: 19px;
  position: relative;
  user-select: none;
}
.bsRatingAcheived {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  margin-left: 7px;
}
.bsRatingTotal {
  display: inline-block;
  opacity: 0.5;
  margin-left: 2px;
  letter-spacing: 1px;
}
.bsRatingCount {
  margin: 1px 0 0 27px;
  opacity: 0.5;
}
.synopsisMobileWrapper {
  font-size: 16px;
  color: #666666;
  background-color: #ffffff;
}
.bsRatingPlayWrapper {
  z-index: 2;
  position: relative;
  min-height: 125px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bsRatingPlayWrapper .blackOverlayshow {
  width: 60px;
}
.bsRatingContentWrapper {
  /* padding-top: 150px; */
  z-index: 1;
  position: relative;
}
.bsRatingContentSection {
  width: 100%;
  position: relative;
  z-index: 1;
  color: #ffffff;
}
.bsRatingContent {
  width: 100%;
  height: 100%;
}
.bsContentThree {
  padding: 10px 0;
  position: relative;
}
.bsContentHeading {
  display: inline-block;
  margin-bottom: 10px;
}
.bsContentH2 {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}
.bsContentSubTitle {
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
}
.bsContentFirstSection {
  position: relative;
  width: 100%;
  min-height: 137px;
  font-size: 14px;
}
.bsContentDirected {
  color: #ffffff;
  line-height: 1.2;
}
.bsContentDirectedSpan {
  font-weight: 400;
}
.bsDirectedFontSize {
  font-size: 16px;
  font-weight: bold;
}
.bsContentCountry {
  font-weight: 400;
  margin-bottom: 6px;
}
.bsContentCategory {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  font-weight: 400;
}
.bsSubtypeList {
  margin-bottom: 10px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
}
.bsContentTime {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.bsContentTwoSection {
  display: none;
}
.bsTwoIcons {
  display: none;
}
.bsShareWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  z-index: 2;
  width: max-content;
  margin: 0 0 0 auto;
}
.bsShareIcon {
  width: 23px;
  height: 14px;
  position: relative;
  user-select: none;
  margin-left: 7px;
}
.bsShareIcon path {
  fill: transparent;
  stroke: #ffffff;
  -webkit-transition: fill 0.2s;
  transition: fill 0.2s;
}
.bsShareWrapper:hover .bsShareIcon path {
  fill: #fff;
}
.bsInfoBar:not(:last-child) {
  margin-right: 12px;
}
.bsInfoBar {
  list-style: none;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
}
.bsInfoBarWrapper {
  margin-right: 5px;
}
.bsInfoOpacity {
  opacity: 0.5;
}
.bsInfoBar {
  list-style: none;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
}
.bsInfoBarGeneral {
  font-size: 12px;
  margin-right: 8px;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-weight: bold;
  padding: 0 3px;
  opacity: 0.5;
  display: flex;
  align-items: center;
}
.synopsisMobileSection:first-of-type {
  padding-top: 30px;
}
.synopsisMobileSection {
  max-width: 650px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.ourtakeMobileSection:first-of-type {
  padding-top: 30px;
}
.ourtakeMobileSection {
  max-width: 650px;
  /* padding-top: 20px; */
  padding-bottom: 30px;
}
.synopsisMobileTitle {
  font-size: 16px;
  margin: 3px 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}
.ourtakeMobileTitle {
  /* padding-top: 30px; */
  font-size: 16px;
  margin: 3px 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}
.synopsisMobileDesc {
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #666666;
}
.ourtakeMobileDesc {
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #666666;
}

@media (min-width: 768px) {
  .bsRatingPlayWrapper .blackOverlayshow {
    width: 90px;
  }
  .bsIconDown {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    opacity: 0.45;
  }
  .bsCionDownWrapper {
    width: 40px;
    height: 40px;
  }
  .bsTwoIcons {
    display: block;
    position: relative;
    margin: 10px 0 0;
  }
  .bsContentTwoWrap {
    padding-top: 20px;
    /* width: 350px; */
    width: 250px;
  }
  .bsContentTwoSection {
    display: inline-block;
    vertical-align: top;
    color: #ffffff;
    padding: 0;
    font-size: 14px;
    margin: 0 0 0 auto;
  }
  .bsContentFirstSection {
    -webkit-flex: 0 0 350px;
    -ms-flex: 0 0 350px;
    flex: 0 0 350px;
  }
  .bsContentHeading {
    min-height: 40px;
    max-width: 560px;
  }
  .bsContentAllThree {
    display: flex;
    flex-wrap: wrap;
    clear: both;
  }
  .synopsisMobileWrapper {
    display: none;
  }
  .ourtakeMobileWrapper {
    display: none;
  }
  .detailsBannerSection {
    flex-grow: 1;
    display: flex;
  }
  .detailsBannerWrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .bsRatingPlayWrapper {
    min-height: 78px;
  }
  .bsContentThree {
    padding: 30px 0;
  }
  .bsTotalRating {
    padding-top: 35px;
    display: flex;
    justify-content: space-between;
  }
  .bsContentTwoH2 {
    font-size: 16px;
    margin: 3px 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    padding-right: 5px;
    display: inline;
  }
  .css-moives {
    font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
    color: #ffffff;
    display: inline;
  }
  .bsContentTwoWrap {
    padding-top: 20px;
    /* width: 350px; */
    width: 250px;
  }
}

@media (min-width: 1148px) {
  .bsContentTwoWrap:nth-of-type(2) {
    margin-left: 40px;
  }
  .bsContentTwoWrap {
    float: left;
    padding-top: 0;
  }
  .css-moives {
    display: block;
  }
  .bsContentTwoH2 {
    padding-right: 0;
    display: block;
    margin: 1px 0 8px;
  }
  .bsContentTwoWrap {
    float: left;
    padding-top: 0;
  }
  .bsContentTwoSection {
    display: block;
    margin: 0 0 0 40px;
  }
  .bsContentHeading {
    max-width: 960px;
  }
  .bsContentThree {
    padding: 30px 0 10px;
  }
  .bsContentAllThree {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
@media (min-width: 1528px) {
  .bsContentTwoSection {
    width: auto;
    margin: 0 0 0 55px;
  }
  .bsContentTwoWrap {
    /* width: 525px; */
    width: 408px;
  }
  .bsContentTwoWrap:nth-of-type(2) {
    margin-left: 55px;
  }
  .bsContentTwoWrap {
    /* width: 525px; */
    width: 408px;
  }
  .detailsBannerSection {
    min-height: 580px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .detailsBannerImgWrapper {
    max-height: 550px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .bsRatingContentWrapper {
    /* max-height: 9500px; */
    /* padding-top: 130px; */
    padding-top: 30px;
  }
}
@media (min-width: 250px) and (max-width: 660px) {
  .bsRatingContentWrapper {
    /* max-height: 9500px; */
    /* padding-top: 130px; */
    padding-top: 30px;
  }
}

/*  */
@media (max-width: 768px) {
  .bsRatingContentSection {
    height: 342px;
  }
  .externalLinks {
    /* height: unset;
    max-width: 95vw; */
    display: none;
  }

  .externalLinkSpacing {
    flex-wrap: wrap;
  }
}

.trailerMobile {
  font-size: 12px;
  text-transform: uppercase;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 999px;
  padding: 6px 13px;
  transition: border-color 0.15s ease 0s, background-color 0.15s ease 0s;
  display: flex;
  align-items: center;
  width: 100px;
  background-color: rgb(0, 20, 137);
}

.trailerMobilePlay {
  width: 10px;
  height: 11px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 10px;
}

.TrailerShareWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .TrailerShareWrapper {
    display: none;
  }
}

/*  */

@media (min-width: 1148px) and (max-width: 1527px) {
  .theseListFlex > .theseListFlexBox:nth-of-type(n + 4) {
    display: none;
  }
  .theseListFlex > .theseListFlexBox:nth-child(3) {
    margin-right: 0;
  }
  .showingCategoryUL .showingCategoryLI:nth-child(3) {
    margin-right: 0;
  }
}

.autoSlider .individualBox .moviePoster {
  position: relative;
}
.hpBanner .blackOverlayshow,
.dotsSlider .blackOverlayshow {
  display: block;
}

@media (max-width: 620px) {
  .playSVGshow {
    width: 82px;
  }
}

@media (max-width: 768px) {
  .mb__external__links__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 15px 0;
  }
}

@media (min-width: 769px) {
  .mb__external__links__wrapper {
    display: none;
  }
}

/* ********************************Key Articles************************* */

.articleSection {
  /* background-color: white; */
  /* padding-bottom: 20px; */
}

@media (min-width: 320px) {
  .articleSectionBoxPadding {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .articleSectionBoxPadding {
    height: 202px;
    padding-bottom: 20px;
  }
  .articleSectionImg {
    padding-right: 10px;
    height: 100%;
    /* width: 50%; */
    min-width: 360px;
  }
  .articleSectionText {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 50%;
    padding: 0 10px;
  }
  .articleSectionTextAuthor {
    padding-bottom: 5px;
  }
}

@media (min-width: 1148px) {
  .articleSectionBoxPadding {
    height: 249px;
  }
  .articleSectionImg {
    /* width: 41.7%; */
  }
  .articleSectionText {
    width: 58.3%;
  }
  .articleSectionTitle {
    font-size: 24px;
  }
}

@media (min-width: 1528px) {
  .articleSectionBoxPadding {
    height: 201px;
  }
  .articleSectionImg {
    /* width: 50%; */
  }
  .articleSectionText {
    width: 50%;
  }
  .articleSectionTitle {
    font-size: 20px;
  }
  .articleSectionBox {
    width: calc(50% - 16px);
  }
  .articleSectionRowCol {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media (max-width: 350px) {
  .personalisationSeperate {
    padding-left: 0;
  }
}
