.thumbnail__grid__container {
  margin: 68px 0px 0;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 1500px) {
  .thumbnail__grid__container {
    margin: 0 14px;
    margin-top: 68px;
    grid-template-columns: repeat(3,1fr);

  }}
@media (max-width: 1277px) {
  .thumbnail__grid__container {
    margin: 0 14px;
    margin-top: 68px;
    
  }
}
@media (max-width: 987px) {
  .thumbnail__grid__container {
    margin: 0 14px;
    grid-template-columns: repeat(3,1fr);
    margin-top: 68px;
  }
}
@media (max-width: 767px) {
  .thumbnail__grid__container {
    margin: 0 15px;
    margin-top: 68px;
    display: flex;
  }
}
