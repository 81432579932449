.fade-in-text {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;

  color: white;
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.aboutUsCont {
  font-size: 16px;
  padding-bottom: 100px;
}

/* @media screen and (max-width: 680px) {
  .aboutUsCont {
    height: 600px;
  }
} */

div.aboutUsCont div.contents { 
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding: 100px 20px 0;
}

div.aboutUsCont div.contents div.left { 
  width:25% ;
  margin-right: 20px;
}

div.aboutUsCont div.contents div.left div.logoContainer { 
  width: 100%;
}

div.aboutUsCont div.contents div.left div.logoContainer img { 
  width: 100%;
  display: block;
}

div.aboutUsCont div.contents div.right { 
  width: 70%;
}

div.aboutUsCont div.contents div.right span.link { 

}

div.aboutUsCont div.contents div.right span.link a { 

}

div.aboutUsCont div.contents div.right p { 
  font-size: 18px;
}



div.aboutUsCont div.contents div.right div.apps { 
  display: flex;
  align-items: center;
}

div.aboutUsCont div.contents div.right div.apps div.imageContainer { 
  width: 60px;
  margin-right: 20px;
  cursor: pointer;
}
div.aboutUsCont div.contents div.right div.apps div.imageContainer:last-child { 
  margin-right: 0;
}

div.aboutUsCont div.contents div.right div.apps div.imageContainer img { 
  display: block;
  width: 100%;
  border-radius: 10px;
}
div.aboutUsCont div.contents div.right div.apps div.imageContainer.svg { 
 margin-right: 40px;
}
div.aboutUsCont div.contents div.right div.apps div.imageContainer.svg svg { 
  width: 90px !important;
  height: 90px !important;
}

/* responsive  */
@media all and (max-width:1400px){
  div.aboutUsCont div.contents {
    width: 70%;
  }
}

@media all and (max-width:1200px){
  div.aboutUsCont div.contents {
    width: 80%;
  }
}

@media all and (max-width:980px){
  div.aboutUsCont div.contents {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  div.aboutUsCont div.contents div.left{
   margin: 0 auto 20px;
  }
  div.aboutUsCont div.contents div.right{
    width: 100%;
   }
   div.aboutUsCont div.contents div.right div.apps {
    justify-content: center;
}
}

@media all and (max-width:480px){
  div.aboutUsCont div.contents div.right div.apps {
    flex-wrap: wrap;
  }
}