.modal-rating-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}

.modal-rating-content {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 50vw;
  /* height: 75vh; */
}

.modal-rating-close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.3;
  color: #000;
  font-size: 28px !important;
  z-index: 2;
  cursor: pointer;
}

.modal-rating-close-btn:hover {
  opacity: 1;
  transition: 500ms;
}

.modal-rating-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgb(234, 234, 234);
  padding: 18px 27px 22px;
  position: relative;
}

.modal-rating-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-rating-title > h1 {
  font-size: 24px;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-weight: bold;
}

.modal-rating-director {
  font-weight: bold;
}

.rating-star {
  margin-top: 23px;
}

.modal-rating-alert-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: -13px;
}

.modal-rating-alert-text {
  font-size: 13px;
  padding: 4px 30px;
  display: inline-block;
  color: rgb(50, 50, 50);
  background-color: rgb(251, 231, 37);
}

.modal-rating-textArea {
  color: rgb(50, 50, 50);
  flex: 1 1 0%;
  width: 100%;
  padding: 0px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  resize: none;
  min-height: 100px;
  height: 100%;
}

.modal-rating-body {
  padding: 0px 22px 22px 27px;
  margin-top: 33px;
}

/* footer */

.modal-rating-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
.rating-save-btn {
  font-family: DMSans, Helvetica, Arial, "Lucida Grande", sans-serif;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.5;
  color: white;
  background-color: rgb(0, 20, 137);
  border: 1px solid rgb(0, 20, 137);
  font-size: 14px;
  padding: 14px 30px;
}

.rating-save-btn:disabled {
  opacity: 0.5;
  cursor: auto;
}

@media (min-width: 1300px) {
  .modal-rating-content {
    max-width: 600px;
  }
}

@media (max-width: 950px) {
  .modal-rating-content {
    width: 90vw;
  }
}
@media (max-width: 450px) {
  .modal-rating-content {
    width: 95vw;
  }
}
