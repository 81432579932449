.modal-trailer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}

.modal-trailer-content {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.modal-trailer-title {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.modal-trailer-title-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 0px 12px;
}

.modal-close-btn {
  border: none;
  outline: none;
  padding: 0px;
  cursor: pointer;
  margin: 0px 0px 0px auto;
  width: 30px;
  opacity: 0.9;
  color: #fff;
  font-size: 28px !important;
}

.modal-trailer-detail-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  color: #fff;
}

.modal-trailer-showName {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.modal-trailer-metadata {
  color: rgb(246, 246, 246);
  font-size: 14px;
  text-transform: uppercase;
}

/* @media (min-width: 1300px) {
  .modal-trailer-body {
    width: 45vw;
  }
} */
