.standardSlider {
  margin: 20px 0;
}

.standardSliderTextMobile {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #1f1f1f;
  line-height: 12px;
  padding: 0 20px 12px;
}

.standardSliderWrapper {
  position: relative;
  overflow: hidden;
}

.prevMask {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 98%;
  width: 138px;
  background-image: linear-gradient(
    180deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(254, 254, 254, 0.9) 30%,
    rgba(236, 236, 236, 0.5) 100%
  );
  z-index: 1;
}

.sliderTrack {
  /* margin-left: 138px;
  margin-right: 50px; */
  margin-left: 0;
  margin-right: 0;
}

.browseMoreStyle{
  cursor: pointer;
  /* height: 18rem; */
  /* width: 100%; */
  background: #bfbfbf24;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-family: Copperplate Gothic Light;
  font-size: 5rem;
  color: rgb(239, 209, 209);
  position: absolute;
  width: 304px !important;
  height: 100%;
}

.browseMoreStyle:hover{
  background: rgba(252, 178, 51, 0.34);
  color: rgb(226, 255, 255);
  transition: background 1s , color 1s;
}



.standardSliderHead {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
}

.slick-list > div {
  margin-left: 0;
}

.slick__carouselNext {
  right: 16px !important;
  transform: rotate(180deg) !important;
  top: 46% !important;
  z-index: 2 !important;
}

.slick__carouselNext__svg,
.slick__carouselPrev__svg {
  width: 12px;
}

.slick__carouselPrev {
  left: 69px !important;
  top: 46% !important;
  z-index: 2 !important;
}

.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.standardSlider .slick-track > .slick-slide:first-child {
  width: 138px !important;
}

.sliderNextArrow {
  padding: 160% 10px 160% 4px;
  background: #f8f9fa61;
  margin: -80px 0px 0px -16px;
  width: 40px;
}

.sliderNextArrow:hover {
  background: #ccccccc2;
}

.sliderPrewArrow {
  padding: 80px 0px 0px 70px;
  margin: 0;
  width: 136px;
  background: #f8f9fa61;
  margin-left: -69px;
  height: 189px;
  margin-top: -79px;
}

.sliderPrewArrow:hover {
  background: #ccccccc2;
}

/* ****************************************************** */

@media (min-width: 1148px) {
  .standardSlider {
    margin: 35px 0;
  }
  .standardSliderTextMobile {
    display: none;
  }
  .standardSliderHead {
    display: block;
    width: 138px;
    padding-left: 20px;
    padding-right: 8px;
    overflow: hidden;
  }
  .standardSliderText {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    color: #1f1f1f;
    line-height: 12px;
    word-break: break-word;
  }
}

@media (min-width: 100px) and (max-width: 1147px) {
  .prevMask {
    display: none;
  }
  .standardSlider .slick-track > .slick-slide:first-child {
    display: none;
  }
  .sliderTrack {
    margin-left: 20px;
    margin-right: 20px;
  }
}
