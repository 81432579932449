.bannerImg,
.bannerImgMobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerImg {
  display: block;
  width: 100%;
}

img.bannerImgMobile {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.asOverlayContent {
  position: absolute;
  bottom: 0;
  color: #fff;
  margin: 0 138px;
}

.asHead {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.asTitle {
  max-width: 338px;
  font-size: 28px;
  font-weight: bold;
  line-height: 0.9;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  max-height: calc(3.5em);
  margin-bottom: 8px;
  text-transform: uppercase;
  overflow-wrap: break-word;
}

.asContent {
  width: 338px;
}

.asMaker {
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
  font-size: 12px;
  display: inline-block;
}
.asAuthor {
  font-weight: 700;
}
.asDescription {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 50px;
}

.dotsSlider {
  margin-bottom: 20px;
}

.slick-dots {
  bottom: 10px !important;
}

.hpBannerPlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.hpBannerPlay .blackOverlay {
  display: block;
}
.hpBannerPlay:hover .blackOverlay {
  display: block;
}
.bcPlayButtonSize {
  position: relative;
  z-index: 1;
  width: 70px;
}

.hpBanner .blackOverlay,
.dotsSlider .blackOverlay {
  display: block;
}

.watch__list__button {
  display: none;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 30px 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.individualBox:hover .watch__list__button {
  display: block;
}

/* ************************ */

@media (max-width: 620px) {
  .asContent {
    width: 80vw;
  }
  .playSVG__mobile {
    width: 58px !important;
  }
  .individualBox .watch__list__button {
    display: block;
    padding: 25px 20px;
  }
}

@media (min-width: 320px) and (max-width: 1147px) {
  .bannerImg {
    display: none !important;
  }
  .asOverlayContent {
    margin: 0 20px;
  }
  img.bannerImgMobile {
    height: 554px;
  }
  .dotsSlider .autoSlider {
    height: 554px;
  }
}

@media (min-width: 1148px) {
  .bannerImgMobile {
    display: none !important;
  }
  .asHead {
    font-size: 14px;
  }
  .asTitle {
    font-size: 46px;
    letter-spacing: -0.5px;
  }
  .asMaker {
    font-size: 14px;
    line-height: 18px;
  }
  .bcPlayButtonSize {
    width: 80px;
  }
}

@media (min-width: 768px) {
  .asDescription {
    margin-bottom: 55px;
  }
}
