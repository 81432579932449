.thumbnail__wrappers {
  width: fit-content;
  color: rgb(255, 255, 255);
  height: 100%;
  background-color: rgb(234, 234, 234);
  position: relative;
  /* margin: 8px 8px 0 8px; */

}

.thumbnail__containers {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.thumbnail__images {
  visibility: visible;
  width: 400px;
  /* height: 230px; */
  object-fit: cover;
  font-family: "object-fit: cover;";
  vertical-align: top;
}

.thumbnail__overlay__containers {
  text-shadow: rgb(0 0 0 / 9%) 0px 0px 10px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: background-color 175ms ease-in-out 0s;
  background-color: transparent;
}

.css-1dtqbqs {
  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.376) 0%, transparent 50%);
  width: 100%;
  height: 100%;
}

.thumbnail__overlay__text__containers {
  font-size: 12px;
  position: absolute;
  top: 5%;
  padding: 0px 70px 9px;
  width: 100%;
}
.thumbnail__desc__spans:hover {
  text-decoration: underline;
}
.vl {
  margin-left: 3%;
  position: absolute;
  top: 0px;
  left: 13%;
  font-size: 28px;
  color: white;
  z-index: 1;
  border-left: 3px solid white;
  height: 45%;
  animation: slideDown 2s ease-out;
  overflow: hidden;
}
.personalisationEpisode {
  position: absolute;
  /* position: relative; */
  z-index: 2;
  margin-top: -45px;
  right: 5%;
  font-size: 50px;
}
.thumbnail__containers:hover + .personalisationEpisode {
  display: inline-block;
}
.personalisationSpacing {
  display: flex;
  /* display: none; */
  justify-content: space-between;
  align-items: flex-end;
}
.personalisationSeperate:hover {
  opacity: 0.7;
}
.personalisationSeperate:first-of-type {
  padding-left: 0px;
}
.personalisationSeperate {
  padding-left: 12px;
  /* display: none; */
}
.personalisationRatingBtn {
  appearance: none;
  color: inherit;
  background: no-repeat transparent;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.personalisationBtnBox {
  width: 28px;
  height: 28px;
  position: relative;
  user-select: none;
}
.bcPlayButtonSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.personalisationListBtn {
  display: flex;
  appearance: none;
  color: inherit;
  background: no-repeat transparent;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}
.personalisationCursor {
  cursor: pointer;
}

.thumbnail__titles {
  max-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.subscription__buttons__container {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.subscription_button {
  background: #f8d406;
  color: #fff;
  padding: 5px 10px;
  border-radius: 18px;
  animation: blinker 1s linear infinite;
  margin-right: 10px;
  font-size: 12px;
}

.episode_synopsis {
  max-height: 5.5em;
  display: -webkit-box;
  width: 400px;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: #666666;
  -webkit-box-orient: vertical;
  font-size: 15px;
  line-height: 1.4;
  text-overflow: ellipsis;
  padding: 5px 5px 0 5px;
  margin-bottom: 5px;
  height: 70px;
}

.thumbnail__descs {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: lighter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnail__desc__spans {
  font-weight: bold;
}

.thumbnail__playButtons {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  z-index: 3;
  top: 34%;
  cursor: pointer;
}

.thumbnail__adds {
  display: none;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
  padding: 12px 16px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.thumbnail__wrappers:hover .thumbnail__adds {
  display: block;
}

.playGs {
  fill: none;
}

.playSVGs:hover .playGs {
  fill: #001489;
}

.add__button {
  display: block;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 12px 12px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.thumbnail__overlay__containers:hover .add__buttons {
  display: block;
}

.thumbnail__wrappers .thumbnail__trailer__eyes {
  display: block;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 12px 0 0 16px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.episodenumber {
  /* max-height: 2.1em; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  left: 16px;
  color: white;
  font-size: 300%;
  font-weight: 600;
}

.episode__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.readonly-rating-star {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.ratingStar {
  font-size: 2.2rem !important;
  color: rgb(255 236 48 / 96%) !important;
  /* color: rgb(251, 231, 37) !important; */
}

@media (max-width: 620px) {
  .thumbnail__images {
    width: 272px;
    /* height: 156px; */
  }
  .thumbnail__titles {
    font-size: 16px;
  }
  .episode_synopsis {
    width: 272px;
    height: 58px;
    font-size: 12px;
  }
  .thumbnail__descs {
    font-size: 12px;
  }
  .playSVGThumbs {
    width: 36px;
  }
  .thumbnail__wrappers .thumbnail__trailer__eyes {
    display: block;
  }
}

@media (max-width: 1535px) {
  .thumbnail__images {
    width: 336px;
    /* height: 156px; */
  }

  .episode_synopsis {
    width: 336px;
    height: 58px;
    font-size: 12px;
  }
}


@media (max-width: 1528px) {
  .thumbnail__images {
    width: 320px;
  }

  .episode_synopsis {
    width: 320px;
    height: 58px;
    font-size: 12px;
  }
}

@media (min-width: 1528px) and (max-width: 1528px){
  .thumbnail__images {
    width: 330px;
  }

  .episode_synopsis {
    width: 330px;
    height: 58px;
    font-size: 12px;
  }
}


.thumbnail__grid__containers {
  margin: 0 1%;
  /* margin-top: 68px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
}

@media (max-width: 767px) {
  .thumbnail__grid__containers {
    margin: 0 15px;
    /* justify-content: center; */
    column-gap: 20px;
    row-gap: 20px;
    /* margin-top: 68px; */
  }
}
.showingCategoryWrapper {
  max-width: 100%;
}
@media (min-width: 320px) {
  .showingCategoryWrapper {
    max-width: 385px;
  }
}
@media (min-width: 768px) {
  .showingCategoryWrapper {
    max-width: 750px;
  }
}
@media (min-width: 1148px) {
  .showingCategoryWrapper {
    /* max-width: 1280px; */
    max-width: 1024px;
    padding: 0;
  }
}
@media (min-width: 1528px) {
  .showingCategoryWrapper {
    /* max-width: 1510px; */
    max-width: 1408px;
  }
}
.showingEpisodes {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding-top: 55px;
  padding-bottom: 12px;
}
.showingEpisodesTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}
.showingEpisodesTitle {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #666666;
}
.showingEpisodesShowAll {
  color: #666666;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
}

@media (max-width: 550px) {
  .ratingStar {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 350px) {
  .personalisationSeperate {
    padding-left: 0;
  }
}

@keyframes slideDown {
  0% {
    height: 0;
  }
  100% {
    height: 45%;
  }
}

