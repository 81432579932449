.category__name {
  /* margin: 86px 0 0; */
  color: rgba(67, 217, 241, 0.8705882352941177);
  font-weight: 700;
}

.category__name .category__name__text {
  margin: 0 60px;
  padding-top: 90px;
  color: #000;
  /* font-size: 4.46667rem; */
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.category__banner__wrapper {
  margin-top: 51px;
}

.category__banner__image {
  width: 100%;
  position: relative;
}

.category__banner__image > img {
  width: 100vw;
  height: calc(100vh - 51px);
  object-fit: cover;
}

.banner__content__wrapper {
  position: absolute;
  bottom: 40px;
  margin: 25px 60px;
  color: white;
  font-size: 14px;
}

.banner__category__name {
  padding-top: 90px;
  color: #fff;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 8px;
  text-transform: uppercase;
  overflow-wrap: break-word;
  max-width: 82vw;
}

.synopsis__container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  margin-bottom: 8px;
  overflow-wrap: break-word;
}

.banner__arrow {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

@media (max-width: 767px) {
  .category__name .category__name__text {
    margin: 0 15px;
    padding-top: 50px;
    color: #000;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .banner__content__wrapper {
    margin: 25px 15px;
  }
  .category__banner__image > img {
    height: calc(75vh - 51px);
  }
  .banner__category__name {
    font-size: 2rem;
  }
  .banner__content__wrapper {
    font-size: 12px;
  }
}
