.thumbnail__wrapper {
  /* width: 100%; */
  width: 98%;
  color: rgb(255, 255, 255);
  height: 100%;
  background-color: rgb(127, 93, 64);
  /* background-color: white; */
  position: relative;
  margin: 8px;
}

.thumbnail__container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.thumbnail__image {
  visibility: visible;
  /* width: 370px;
  height: 100%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  vertical-align: top;
}
@media all and (max-width: 1277px) {
  .thumbnail__image {
    width: 100% ;
    height: 100% ;
  }
}

@media all and (max-width: 1000px) {
  .thumbnail__image {
    width: 100% ;
    height: 100% ;
  }
}

.thumbnail__overlay__container {
  text-shadow: rgb(0 0 0 / 9%) 0px 0px 10px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: background-color 175ms ease-in-out 0s;
  background-color: transparent;
}

.css-1dtqbq {
  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.376) 0%, transparent 50%);
  width: 100%;
  height: 100%;
}

.thumbnail__overlay__text__container {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  padding: 0px 16px 12px;
  width: 100%;
}

.thumbnail__title {
  max-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
}
@media (max-width: 1000px) {
.thumbnail__title {
  max-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 1.4;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
}
}

.thumbnail__desc {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: lighter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1000px) {
  .thumbnail__desc {
    font-size: 10px;
  }
  
}

.thumbnail__desc__span {
  font-weight: bold;
}

.thumbnail__playButton {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  z-index: 3;
  top: 34%;
  cursor: pointer;
}

.thumbnail__add {
  display: none;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
  padding: 12px 16px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.thumbnail__wrapper:hover .thumbnail__add {
  display: block;
}

.playG {
  fill: none;
}

.playSVG:hover .playG {
  fill: #001489;
}

.add__button {
  display: block;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 12px 12px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.thumbnail__overlay__container:hover .add__button {
  display: block;
}

.thumbnail__wrapper .thumbnail__trailer__eye {
  display: block;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 12px 0 0 16px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

@media (max-width: 1000px) {
  .playSVGThumb {
    width: 29px !important;
  }
}

@media (max-width: 620px) {
  .thumbnail__image {
    width: 100%;
    height: 100%;
  }
  .thumbnail__title {
    font-size: 16px;
  }
  .playSVGThumb {
    width: 36px;
  }
  .thumbnail__desc {
    display: none;
  }
  .thumbnail__wrapper .thumbnail__trailer__eye {
    display: block;
  }
}



.divfree {
  display: flex;
  align-content: center;
  align-items: center;
}

.divfree span img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  object-fit: contain;
}
@media (max-width:1000px) {
  .divfree span img {
    width: 32px;
    height: 32px;
  }
}

.sliderItem__showname {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
