@keyframes glowing {
  0% {
    background-color: #001489;
    box-shadow: 0 0 5px #001489;
  }
  30% {
    opacity: 0.1;
    /* background-color: #49e819;
      box-shadow: 0 0 20px #49e819; */
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}
.live-button {
  animation: glowing 1300ms infinite;
}

.header {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eaeaea;
}

.navigation {
  height: 50px;
  width: 100%;
  display: flex;
}

.menuNav {
  padding-right: 21px;
  padding-left: 21px;
  height: 100%;
  display: flex;
  align-items: center;
}
.menuNav a {
  margin-bottom: 2px;
  display: inline-block;
}

.logoPlaceholder {
  /* width: 75px; */
  width: 100%;
}

.searchBar {
  border-left: 1px solid #eaeaea;
  flex-grow: 2;
}
.searchBar form {
  width: 270px;
  /* width: 270px;  350 */
  position: relative;
}
/* .searchBar > form > div:focus {
  background-color: red;
} */

.searchBar .searchIcon {
  position: absolute;
  top: calc(50% - 11px);
  left: 21px;
  z-index: 3;
  width: 21px;
  height: 22px;
  top: calc(50% - 8px);
  width: 15px;
  height: 16px;
}
.searchBar .searchInput {
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 20px;
  display: block;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  color: #7d7d7d;
  position: relative;
  z-index: 2;
  background: transparent;
  height: 60px;
  padding-left: 49px;
  padding-right: 38px;
  outline: none;
  height: 50px;
  padding: 12px 15px;
  padding-left: 42px;
  font-size: 14px;
  text-align: left !important;
}

.searchInput::placeholder {
  color: #7d7d7d !important;
  text-align: left !important;
}

.menuItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menuLinks {
  margin-right: 18px;
  font-size: 14px;
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 700;
  color: #323232;
  text-transform: uppercase;
}

.loginBox {
  border-left: 1px solid #eaeaea;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.loginLink {
  cursor: pointer;
  font-size: 14px;
  margin: 0 18px 0 21px;
  font-family: DM Sans, Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 700;
  color: #7d7d7d;
  text-transform: uppercase;
}

.hamburgerDesktop {
  position: relative;
  display: block;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menuContainer {
  padding: 5px 5px 5px 4px;
  cursor: pointer;
  margin-right: 18px;
}
.hamburgerIcon {
  width: 13px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.menuClick {
  position: absolute;
  top: 63px;
  right: 8px;
  left: auto;
  min-width: 173px;
  background-color: white;
  box-shadow: 0 -1px 8px rgb(0 0 0 / 13%);
  z-index: 3;
  display: block;
}

.categoryBox {
  position: absolute;
  top: 49px;
  right: 24px;
  left: auto;
  min-width: 200px;
  background-color: white;
  box-shadow: 0 -1px 8px rgb(0 0 0 / 13%);
  z-index: 3;
  display: block;
}

.menuClick__d__arrow {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 13px;
  height: 13px;
  background: #ffffff;
  position: absolute;
  top: -5px;
  right: 14px;
  z-index: 1;
  box-shadow: 0 -1px 8px rgb(0 0 0 / 13%);
  display: block;
}

.menuClick__ul {
  padding: 16px 0 150px;
  width: 100%;
  z-index: 3;
  position: relative;
  background-color: #ffffff;
}

.menuClick__ul__li {
  padding: 0;
  font-family: DMSans, Helvetica, Arial, "Lucida Grande", sans-serif;
  display: block;
}

.menuClick__ul__li__a {
  color: #7d7d7d !important;
  display: block;
  padding: 0px 25px;
  font-size: 20px;
  line-height: 42px;
  font-weight: normal;
  cursor: pointer !important;
}

.menuClick__ul__li__a:hover {
  color: #000 !important;
}

.fadeinblack {
  opacity: 1;
  display: block;
  z-index: 9;
}

#nav-icon3.open > span {
  background: white;
}

/* hamburger menu */

#nav-icon3 {
  width: 22px;
  height: 28px;
  position: relative;
  margin: 17px 0 0 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #7d7d7d;
  opacity: 1;
  left: 0;
  border-radius: 8px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 8px;
}

#nav-icon3 span:nth-child(4) {
  top: 16px;
}

#nav-icon3.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.ha__link {
  cursor: pointer;
}

.ha__link:hover {
  color: #0056b3 !important;
}

.searchInput:focus {
  background-color: #f6f6f6 !important;
  border-bottom: 1px solid #f6f6f6 !important;
}

.css-45c27d {
  border-top: 1px solid #eaeaea;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.browse__tab:hover {
  color: #0056b3;
  cursor: pointer;
}

.categoryBox-overflowscroll {
  overflow: auto;
  max-height: 60vh !important;
  margin-bottom: 0;
  margin-top: 0;
}

.categoryTitle {
  text-transform: uppercase;
  padding-bottom: 8px !important;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #eaeaea;
}
.categoryTitle .menuClick__ul__li__a {
  font-weight: 600;
}

/* ************************************************** */

@media (min-width: 1528px) {
  .loginLink {
    margin: 0 21px;
  }
  .header {
    /* max-width: 1510px; */
    margin: 0 auto;
  }
}

@media (min-width: 1148px) {
  .mobileMenu,
  .menu-overlay {
    display: none;
  }

  .menuClick__ul {
    height: 100%;
    padding: 11px 0;
  }

  .menuClick__ul__li {
    font-family: DMSans, Helvetica, Arial, "Lucida Grande", sans-serif;
    padding: 0 15px;
  }
  .menuClick__ul__li__a {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  }
}

@media (min-width: 320px) and (max-width: 1147px) {
  .navigation {
    justify-content: center;
  }
  .navigation .menuNav {
    padding-right: 0;
    padding-left: 0;
  }
  .searchBar,
  .hamburgerDesktop,
  .menuItems > div:first-child,
  .menuItems > a,
  .browse__tab {
    display: none;
  }
  .loginBox {
    position: absolute;
    right: 0;
    height: auto;
    border: 0;
  }
  .mobileMenuLinks {
    color: #7d7d7d !important;
    display: block;
    padding: 0px 25px;
    font-size: 20px;
    line-height: 42px;
    font-weight: normal;
  }
  .fadeinEnter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1005;
    opacity: 0;
    display: none;
  }
  body.freezeBody .fadeinEnter {
    opacity: 1;
    display: block;
    z-index: 9;
  }
  .freezeBody #nav-icon3 span {
    background: #fff;
  }
  .menu-overlay {
    background: #fff;
    height: 100vh;
    width: 80%;
    left: -80%;
    z-index: 13;
    position: absolute;
    overflow: hidden;
    z-index: 999999;
  }
  .menu-overlay li {
    color: #fff;
    list-style: none;
    padding: 0;
    text-align: left;
    position: relative;
  }
  .mobileSearchIconBox {
    position: relative;
    z-index: 2;
    background-color: white;
    height: 100%;
    height: 100%;
    border-bottom: 1px solid #eaeaea;
  }
  .mobileSearchIcon {
    position: absolute;
    top: calc(50% - 11px);
    left: 21px;
    z-index: 3;
    width: 21px;
    height: 22px;
  }
  .mobileSearchInput {
    font-size: 20px;
    display: block;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
    color: #7d7d7d;
    position: relative;
    z-index: 2;
    background: transparent;
    height: 60px;
    padding-left: 49px;
    padding-right: 38px;
    outline: none;
  }
  .mobileMenuUL {
    margin-top: 20px;
  }
  .mobileMenuLinks {
    color: #7d7d7d !important;
    display: block;
    padding: 0px 25px;
    font-size: 20px;
    line-height: 42px;
    font-weight: normal;
  }
  .mobileMenu {
    position: absolute;
    left: 0;
    z-index: 12;
  }
}

@media (min-width: 1148px) {
  .header .navigation {
    max-width: 1130px;
    margin: 0 auto;
  }
}

.work-guid-player {
  width: 60vw;
}

.work-guid-close {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 3;
}
@media (max-width: 1148px) {
  .work-guid-player {
    width: 90vw;
  }
}
