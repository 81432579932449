.liveTvGuide .movieTextWrapper .movieTextFlex h3 .liveGuideTitle {
  color: rgb(0, 0, 0) !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 1.4;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical;
}

@media (max-width: 760px) {
  .movieCatYear1 .webLivePeriod,
  .webLiveDate1 {
    display: none !important;
  }
}
