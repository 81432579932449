.videoSettingsIcon {
  object-fit: contain;
  width: 19px;
  /* width: 48px;
      height: 24px; */
}

span.vjs-resolution-button-label {
  display: none;
}

.closingButton {
  text-align-last: right;
  position: absolute;
  top: 18px;
  z-index: 10;
  right: 12px;
}

.closingButton .close-video-button:hover {
  width: 37px;
  opacity: 1 !important;
  transition: ease-out 0.5s;
  background: #001489;
  /* background: #fbcd37; */
}

.video_container .vjs-menu-content .vjs-menu-item-text {
  text-transform: capitalize;
}

@media only screen and (min-width: 1500px) {
  .closingButton {
    right: 25px;
  }
}
