.homeBannerAdj {
  margin-top: -70px;

  @media only screen and (max-width: 1024px) {
    margin-top: -70px;
  }
  @media only screen and (min-width: 1400px) {
    margin-top: -10px;
  }
  @media only screen and (max-width: 575px) {
    // margin-top: -81px; 575px 770px
    margin-top: -81px;
    // margin-top: 30px;
  }
}
