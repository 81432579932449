.screeningRoomTitle {
  margin: 0 0 0 60px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -23px;
}

@media (max-width: 650px) {
  .screeningRoomTitle {
    font-size: 18px;
    margin: 0 0 0 15px;
    margin-bottom: -23px;
  }
}
