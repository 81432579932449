.title {
  font-size: 17px;
  color: #000;
  font-weight: bold;
  transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45); */
  margin: 0 0 1vw 0;
  padding-top: 90px;
  display: flex;
  align-items: center;
}

.search-title {
  font-size: 18px;
  /* color: #e50914; */
  color: rgb(0, 20, 137);
  z-index: 2;
  font-weight: bold;
  margin: 0;
  position: absolute;
  text-decoration: none;
  display: inline-flex;
  min-width: 6em;
  left: 57px;
}

.search__result__container {
  margin: 0 60px;
  margin-top: 68px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.loader__wrapper {
  display: flex;
  justify-content: center;
  margin: 100px auto;
}

.loader__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader__container > h2 {
  font-weight: bold;
}

@media (max-width: 767px) {
  .search__result__container {
    margin: 0 15px;
    margin-top: 68px;
  }
  .title {
    padding-top: 50px;
  }
}
