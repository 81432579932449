.bgImageSection {
  width: 100%;
  margin-bottom: 20px;
}

.bgImageSectionWrapper {
  position: relative;
  width: 100%;
  height: 605px;
}

.bgImagePlaceholder {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 49.819% 65.778%;
  background-size: cover;
}

.bgImageSectionGradient {
  height: 100%;
  width: 100%;
  padding: 20px 0px;
  box-sizing: border-box;
  background: linear-gradient(
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0.2) 60%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.bgImageSectiontext {
  color: rgb(255, 255, 255);
  display: block;
  position: relative;
  padding-bottom: 170px;
  margin: 0 20px;
}

.bgImgSectionHeading {
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 15px;
}

.bgImgSectionHeadingImage {
  width: 300px;
  display: block;
}

.bgImageSectionContent {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
}

.bgImageSectionContentPara {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.bgImageSectionButton {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.bgImageSectionBtn {
  appearance: none;
  background: no-repeat transparent;
  outline: none;
  margin: 0px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  color: rgb(255, 255, 255);
  height: 45px;
  padding: 0px 15px 0px 21px;
  font-size: 15px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 23px;
  transition: color 0.15s ease 0s, background-color 0.15s ease 0s;
}

.bgImageSectionBtnText {
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.1px;
}

.bgImageSectionBtnPlay {
  margin-left: 13px;
  width: 15px;
  height: 16px;
  position: relative;
}

.bgImageSectionBtnSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bgImageSlider svg path {
  stroke: none !important;
}

.bgImageSectionButton > button:hover {
  background-color: rgb(0, 20, 137);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 20, 137);
}
/* *********************************************** */

@media (min-width: 768px) {
  .bgImageSectionGradient {
    padding: 35px 0px;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
  .bgImageSectiontext {
    padding-bottom: 192px;
  }
  .bgImageSectionContent {
    max-width: 348px;
  }
  .bgImageSlider {
    position: relative;
    /* top: -260px; */
    top: -298px;
  }
}

@media (min-width: 1148px) {
  .bgImageSectiontext {
    margin: 0 138px;
  }
  .bgImageSectionContent {
    line-height: 18px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1148px) {
  .bgImageSection {
    min-height: 612px;
    margin-bottom: 35px;
  }
  .bgImageSectionWrapper {
    height: 685px;
  }
}

@media (min-width: 320px) and (max-width: 1147px) {
  .bgImageSlider {
    position: relative;
    top: -230px;
  }
}

@media (min-width: 1700px) and (max-width: 1990px) {
  .bgImageSlider {
    position: relative;
    top: -310px;
  }
}
@media (min-width: 650px) and (max-width: 850px) {
  .bgImageSlider {
    position: relative;
    top: -260px;
  }
}

@media (min-width: 500px) and (max-width: 650px) {
  .bgImageSlider {
    position: relative;
    top: -370px;
  }
}

@media (min-width: 380px) and (max-width: 500px) {
  .bgImageSlider {
    position: relative;
    top: -285px;
  }
}
