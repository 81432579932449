

.activate-style {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: white;
  min-height: inherit;
  margin-top: 90px;
  margin-bottom: 20px;
}
.styles-container-30ESb55q {
  height: 44px;
  position: fixed;
  top: 0px;
  width: 100%;
}
.activate-arrow-back {
  height: 44px;
  top: 0px;
  left: 0px;
  z-index: 4;
  padding: 10px;
  position: fixed;
}
.styles-backArrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.styles-header {
  padding: 12px 0px;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.29px;
  color: #ffffff;
  margin-top: 0px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  font-family: "Roboto-Bold", sans-serif;
}
.styles-box {
 background-color: #1d1000;
  border-radius: 5px;
  padding: 65px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.styles-inputBoxContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
}
.styles-enterCodeWrap {
  display: flex;
  flex-direction: column;
  line-height: 1.41;
  margin-bottom: 48px;
}
.styles-linkTvTitle {
  display: inline;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 14px;
}
.styles-enterCodetext {
  color: #9ba1a9;
  font-size: 14px;
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
}
.styles-input-p5CThI-I {
  /* background-color: #262931; */
  background-color: #3c2305;
  width: 100%;
  /* color: #ffffff; */
  border: none;
  border-bottom: solid 1px #2175d9;
  outline: none;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  height: 60px;
  font-size: 30px;
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
  color: #d38a15;
  letter-spacing: 12px;
}
.styles-input {
  border: 1px solid;
  padding: 9px 0px;
}
.styles-buttonWrapper {
  margin-top: 8px;
  margin-bottom: 4px;
}
.styles-activeFromApp {
  width: 167px;
  margin-right: 68px;
  font-size: 14px;
  font-weight: 300;
  color: #838991;
  font-family: "Roboto-Regular", sans-serif;
}
.styles-primaryButton {
  width: 210px;
  height: 40px;
  border-radius: 4px;
  /* background-image: linear-gradient(to top, #2175d9, #2789ff); */
  background-color:#d38a15; 
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.29px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  font-family: "Roboto-Regular", sans-serif;
}
.activateContainer {
  background-color: #000;
  min-height: calc(100vh - 200px);
}
@media (max-width: 700px) {
  .styles-backBtn {
    display: none;
  }
  .activateContainer {
    padding-top: 0px;
  }
}

@media (max-width: 750px) {
  .styles-input {
    /* background-color: #1a1c21; */
    height: 40px;
    caret-color: #9ba1a9;
    padding: 40px 0px;
    font-size: 27px;
  }
}
@media (max-width: 699px) {
  .activate-style {
    margin-top: 50px;
  }
  .styles-box {
    position: relative;
    padding: 10px;
    box-shadow: unset;
    /* background-color: #1a1c21; */
  }
  .styles-inputBoxContainer {
    margin: 34px 26px;
  }
  .styles-enterCodetext {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    line-height: 1.44;
    color: #9ba1a9;
  }
  .styles-input {
    /* background-color: #1a1c21; */
    height: 40px;
    caret-color: #9ba1a9;
    padding: 40px 0px;
  }
}
.timer {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 13pt;
  margin-bottom: 8px;
  padding-top: 12px;
}
.error-notification {
  border-color: red;
  color: red;
}
