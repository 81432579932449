.individualBox {
  position: relative;
}

.individualBox:hover > .blackOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.playG {
  fill: none;
}

.playSVG:hover .playG {
  fill: #001489;
}

.playCircle {
  stroke: #ffffff;
}

.playCircle:hover {
  stroke: #001489;
}

.moviePoster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.individualBoxContent {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  padding: 0px 16px 12px;
  width: 100%;
  color: #fff;
  display: block;
}

.ibcDate {
  font-size: 12px;
  margin-bottom: 1px;
  font-weight: 700;
  text-transform: uppercase;
}

.ibcTitle {
  max-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.ibcMaker {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: lighter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ibcAuthor {
  font-weight: 700;
}

.blackOverlay {
  background: rgba(15, 15, 15, 0.15);
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
}

.standardSlider .blackOverlay .playSVG {
  height: 41px;
  width: 41px;
}

.blackOverlay a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__item .watch__list__button {
  display: none;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 12px 16px 0 0;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.slider__item .trailer__eye {
  display: none;
  opacity: 1;
  transition: opacity 0.15s ease 0s;
  padding: 12px 0 0 16px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.slider__item:hover .watch__list__button,
.slider__item:hover .trailer__eye {
  display: block;
}

.div__tooltip {
  position: relative;
}

.progressbar__div {
  margin: 0 -16px -12px -16px;
}
.progressbar-border {
  /* border: 1px solid #ccc !important; */
}
.progressbar-grey {
  color: #000 !important;
  background-color: red !important;
}

/* ***************************** */

@media (min-width: 1528px) {
  .individualBoxContent {
    padding: 0px 20px 17px;
  }
}

@media (max-width: 280px) {
  .moviePoster img {
    width: 75vw;
  }
}

@media (max-width: 620px) {
  .ibcDate {
    display: none;
  }
  .ibcTitle {
    font-size: 16px;
  }
  .slider__item .watch__list__button,
  .slider__item .trailer__eye {
    display: block;
  }

  .slider__item .watch__list__button {
    padding: 12px 16px 0 0 !important;
  }

  .individualBox > .blackOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.divfree {
  display: flex;
  align-content: center;
  align-items: center;
}

.divfree span img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  object-fit: contain;
}

.sliderItem__showname {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
