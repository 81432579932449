.payment__container {
  min-height: 70vh;
  padding-top: 65px;
}
.coupon_input {
  color: white;
  background-color: black !important;
  width: 25% !important;
  margin-left: 2.2%;
  height: 43px;
  padding-left: 10px;
  font-size: 20px;
  border-radius: 5px;
  border: 2px solid white;
}

@media screen and (max-width: 494px) {
  .coupon_input {
    color: white;
    background-color: black !important;
    width: 50% !important;
    margin-left: 5%;
  }
}
